import { Input } from "antd";
import { isUrl } from "core/util-functions/src/core/strings";
import { Row } from "core/util-styled-components";
import { GeneralControlProps } from "../../../../types/interfaces";

const InputControl = ({ rest, hookForm }: GeneralControlProps) => {
  return (
    <div>
      {rest.customProps && rest.customProps.type === "file" ? (
        <Row gap="10px">
          <input
            type="file"
            id={rest.name}
            onChange={(e) => {
              if (hookForm.field) {
                hookForm.field.onChange(e.target.files);
              } else {
                hookForm.onChange(e.target.files);
              }
            }}
            defaultValue={isUrl(rest.defaultValue) ? "" : rest.defaultValue}
            {...rest.customProps}
          />
          {rest.defaultValue && isUrl(rest.defaultValue) && (
            <a href={rest.defaultValue} target={"_blank"} rel="noreferrer">
              Ver PDF Cargado
            </a>
          )}
        </Row>
      ) : (
        <Input
          onChange={(e) => {
            rest.onChange && rest.onChange(e);
            if (hookForm.field) {
              hookForm.field.onChange(e);
            } else {
              hookForm.onChange(e);
            }
          }}
          defaultValue={rest.defaultValue}
          {...rest.customProps}
        />
      )}
    </div>
  );
};

export default InputControl;
