// import { DatePicker } from "antd";
import { GeneralControlProps } from "../../../../types/interfaces";
import { DatePickerStyled } from "../FormControl/styled";

const CalendarControl = ({ hookForm, rest }: GeneralControlProps) => {
  return (
    <div>
      <DatePickerStyled
        onChange={(e) => {
          rest.onChange && rest.onChange(e);
          if (hookForm.field) {
            hookForm.field.onChange(e);
          } else {
            hookForm.onChange(e);
          }
        }}
        defaultValue={rest.defaultValue}
        {...rest.customProps}
      />
    </div>
  );
};

export default CalendarControl;
