import { faBars, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Button } from "antd";
import Logo from "App/components/atoms/Logo";
import { useUsuario } from "hooks/ContextUser";
import { Row, Typography } from "core/util-styled-components";
import { NavBarStyled, UserName } from "./styled";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";
const NavBar = ({ setVisible }) => {
  const { usuario } = useUsuario();
  const { isTabletOrMobile } = useMediaQueryCustom();

  return (
    <NavBarStyled justifyContent="space-between" padding="20px 3em">
      <Logo />
      {usuario && (
        <Row gap="1em">
          <UserName gap=".5em" onClick={() => setVisible(true)}>
            <Avatar
              src={
                usuario.user.ruta && usuario.user.ruta !== "undefined"
                  ? usuario.user.ruta
                  : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              }
            />
            {usuario && !isTabletOrMobile && (
              <Typography>{usuario.user.username}</Typography>
            )}
          </UserName>
          {!isTabletOrMobile && (
            <Button
              type="primary"
              shape="circle"
              onClick={() => setVisible(true)}
            >
              <FontAwesomeIcon icon={faBars} />
            </Button>
          )}
        </Row>
      )}
    </NavBarStyled>
  );
};

export default NavBar;
