const _FORMAT_YOUTUBE_WEB_URL = "https://www.youtube.com/watch?v=";
const _FORMAT_YOUTUBE_APP = "https://youtu.be/";
const _FORMAT_YOUTUBE_EMBED = "https://www.youtube.com/embed/";

export const getIdYoutube = (url) => {
  let idYoutube = undefined;
  if (url.includes(_FORMAT_YOUTUBE_WEB_URL)) {
    idYoutube = url.replace(_FORMAT_YOUTUBE_WEB_URL, "").substr(0, 11);
  }

  if (url.includes(_FORMAT_YOUTUBE_APP)) {
    idYoutube = url.replace(_FORMAT_YOUTUBE_APP, "").substr(0, 11);
  }

  return idYoutube;
};

export const getUrlYoutube = (url) => {
  const idYoutube = getIdYoutube(url);
  return _FORMAT_YOUTUBE_WEB_URL + idYoutube;
};

export const getEmbedYoutube = (url) => {
  const idYoutube = getIdYoutube(url);
  return _FORMAT_YOUTUBE_EMBED + idYoutube;
};

export const youtube_validator = (value) => {
  const url = value;
  let idYoutube = getIdYoutube(url);

  return Boolean(idYoutube);
};
