import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "antd";
import { Column, Row, Typography } from "core/util-styled-components";
import { textField } from "core/utils/string";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";
import React from "react";

const Video = ({ data }) => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  return (
    <Row gap="10px">
      <Image
        src={data.Miniatura}
        shape="square"
        size="large"
        style={{
          borderRadius: "10px",
          maxWidth: isTabletOrMobile ? "80px" : "150px",
          width: isTabletOrMobile ? "80px" : "150px",
        }}
      />
      <Column alignItems="flex-start" alignSelf="flex-start" gap="0">
        <strong>{data.Nombre}</strong>
        {!isTabletOrMobile && (
          <Typography>{textField(data.Descripcion, 342)}</Typography>
        )}
        <a href={data.URL} target="_blank" rel="noreferrer">
          <Row gap="10px">
            <FontAwesomeIcon icon={faExternalLink} />
            <Typography>Ver video</Typography>
          </Row>
        </a>
      </Column>
    </Row>
  );
};

export default Video;
