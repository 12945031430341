import { useParams } from "react-router-dom";
import { Container } from "App/components/skeleton/Container";
import {
  Heading,
  Grid,
  Typography,
  Div,
  Row,
  Column,
} from "core/util-styled-components";
import useAxios from "hooks/useAxios";
import React from "react";
import { GridChild } from "core/util-styled-components/components/Grid";
import { Button, Card, Image, Tag } from "antd";
import { geekblue } from "@ant-design/colors";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";

const imageSize = "35px";

const PublicacionEscrita = () => {
  const { id } = useParams();
  const { isTabletOrMobile } = useMediaQueryCustom();

  const PublicacionEscrita = useAxios(
    "GET",
    `/contenidoEscrito/consultar/cliente/${id}`
  );

  return (
    <Div width={isTabletOrMobile ? "98%" : "80%"} margin="20px auto">
      {Array.isArray(PublicacionEscrita.data) &&
        PublicacionEscrita.data.map((item) => (
          <Grid cols={12} gap="10px" spanRow="1/3">
            <GridChild span={isTabletOrMobile ? 12 : 8}>
              <Card
                title={`Publicacion Escrita #${item.IdPublicacion}`}
                extra={
                  <Tag color={geekblue.primary}>
                    <Typography color="white">
                      {item.TipoPublicacion}
                    </Typography>
                  </Tag>
                }
              >
                <Grid cols={4} gap="0px 20px">
                  <GridChild span={3}>
                    <Row justifyContent="space-between">
                      <Heading variant="h2">{item.Nombre}</Heading>
                    </Row>
                  </GridChild>
                  <GridChild span={2}>
                    <Row gap="10px">
                      <Image
                        src={item.AutorAvatar}
                        style={{
                          width: imageSize,
                          height: imageSize,
                          maxWidth: imageSize,
                          minWidth: imageSize,
                          minHeight: imageSize,
                          maxHeight: imageSize,
                          borderRadius: "50%",
                          border: "dashed 2px #FF6D15",
                        }}
                      />
                      <Typography>{item.AutorPublicacion}</Typography>
                    </Row>
                  </GridChild>
                  <GridChild>
                    <Typography color="#c3c3c3">
                      <Column alignItems="flex-end">
                        <span>Fecha Publicacion</span>
                        <span>
                          {
                            new Date(item.FechaPublicacion)
                              .toISOString()
                              .split("T")[0]
                          }
                        </span>
                      </Column>
                    </Typography>
                  </GridChild>
                  <GridChild span={3} style={{ alignSelf: "flex-start" }}>
                    <strong>Tema: </strong>
                    <Typography>{item.TemaPublicacion}</Typography>
                  </GridChild>
                  <GridChild span={3} style={{ alignSelf: "flex-start" }}>
                    <strong>Area: </strong>
                    <Typography>
                      {item.AreaPublicacion
                        ? item.AreaPublicacion
                        : "Sin Registro"}
                    </Typography>
                  </GridChild>
                  <GridChild span={4} style={{ alignSelf: "flex-start" }}>
                    <Column gap="10px">
                      <strong>Sinopsis </strong>
                      <Typography>{item.Sinopsis}</Typography>
                    </Column>
                  </GridChild>
                  <GridChild spanCol={"4/5"} spanRow={"1/5"}>
                    <Image src={item.Portada} style={{ borderRadius: "5px" }} />
                  </GridChild>
                </Grid>
              </Card>
            </GridChild>
            <GridChild span={isTabletOrMobile ? 12 : 4}>
              <Card title="Informacion Adicional">
                <Column gap="10px">
                  <Item
                    label="Editorial"
                    value={item.Editorial ? item.Editorial : "No Registrado"}
                  />
                  <Item
                    label="Fecha Contenido"
                    value={
                      item.FechaContenido
                        ? new Date(item.FechaContenido)
                            .toISOString()
                            .split("T")[0]
                        : "No Registrado"
                    }
                  />
                  <Item
                    label="Año Edicion"
                    value={item.AñoEdicion ? item.AñoEdicion : "No Registrado"}
                  />
                  <Item
                    label="Nro Paginas"
                    value={item.NroPaginas ? item.NroPaginas : "No Registrado"}
                  />
                  <Item
                    label="Idioma"
                    value={
                      item.IdiomaPublicacion
                        ? item.IdiomaPublicacion
                        : "No Registrado"
                    }
                  />
                  <a
                    href={item.PDF}
                    target="_blank"
                    rel="noreferrer"
                    style={{ width: "100%" }}
                  >
                    <Button type="primary" style={{ width: "100%" }}>
                      Ver Contenido
                    </Button>
                  </a>
                </Column>
              </Card>
            </GridChild>
          </Grid>
        ))}
    </Div>
  );
};

const Item = ({ label, value }) => {
  return (
    <Row gap="5px" justifyContent="space-between">
      <strong>{label}</strong>
      <Typography style={{ textAlign: "end" }}>{value}</Typography>
    </Row>
  );
};

export default PublicacionEscrita;
