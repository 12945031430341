// import { Button } from 'react-bootstrap'
import { InputSection, SearchSection } from "./styled";
import { useState } from "react";
import { ReactComponent as SearchSVG } from "assets/img/search.svg";
import { Heading } from "core/util-styled-components";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";

// const urlReciente = "/buscar/articulos-recientes";
// const urlDestacado = "/buscar/articulos-destacados";

const Toolbar = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const { isTabletOrMobile } = useMediaQueryCustom();
  const onSearch = () => {
    navigate(`/cliente/buscar/${value}`);
  };

  return (
    <SearchSection isMobile={isTabletOrMobile}>
      <SearchSVG className="img-search" />
      <InputSection isMobile={isTabletOrMobile}>
        <Heading secondary variant="h2">
          ¡Empieza buscando lo que necesitas!
        </Heading>
        <Input.Search
          size="large"
          value={value}
          onSearch={onSearch}
          onChange={(e) => setValue(e.target.value)}
        />
        {/* <Row gap="1em" justifyContent="center" width="100%">
          <Button link complementary onClick={() => navigate(urlReciente)}>
            Ver publicaciones recientes
          </Button>
          <Button link complementary onClick={() => navigate(urlDestacado)}>
            Ver articulos destacados
          </Button>
        </Row> */}
      </InputSection>
    </SearchSection>
  );
};

export default Toolbar;
