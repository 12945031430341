import { Select } from "antd";
import { GeneralControlProps } from "core/ts_formulario_model/src/types/interfaces";
const { Option } = Select;
const SelectControl = ({ hookForm, rest }: GeneralControlProps) => {
  return (
    <Select
      value={hookForm.field ? hookForm.field.value : hookForm.value}
      onChange={(e: any) => {
        rest.onChange && rest.onChange(e);
        if (hookForm.field) {
          hookForm.field.onChange(e);
        } else {
          hookForm.onChange(e);
        }
      }}
      style={{ width: "100%" }}
      {...rest.customProps}
    >
      {rest.optionsDropdown &&
        rest.optionLabel &&
        rest.optionValue &&
        rest.optionsDropdown.map((item: any, key: number) => (
          <Option value={item[rest.optionValue ? rest.optionValue : key]}>
            {item[rest.optionLabel ? rest.optionLabel : key]}
          </Option>
        ))}
    </Select>
  );
};

export default SelectControl;
