import { Image } from "antd";
import { Typography } from "core/util-styled-components";
import { isUrl } from "core/util-functions/src/core/strings";
import { GeneralControlProps } from "../../../../types/interfaces";
import { ImagenControlStyled, InputButton } from "./styled";

const ImagenControl = ({ hookForm, rest }: GeneralControlProps) => {
  console.log("hookForm", hookForm);
  return (
    <ImagenControlStyled>
      {hookForm.field.value && hookForm.field.value.length > 0 && (
        <Image
          src={
            isUrl(hookForm.field.value)
              ? hookForm.field.value
              : URL.createObjectURL(hookForm.field.value[0])
          }
        />
      )}
      <InputButton>
        <input
          type="file"
          id={rest.name}
          accept="image/*"
          onChange={(e) => {
            if (hookForm.field) {
              hookForm.field.onChange(e.target.files);
            } else {
              hookForm.onChange(e.target.files);
            }
          }}
          defaultValue={isUrl(rest.defaultValue) ? "" : rest.defaultValue}
        />
        <label htmlFor={rest.name}>
          <Typography variant="label" color="#fff">
            Seleccionar
          </Typography>
        </label>
      </InputButton>
    </ImagenControlStyled>
  );
};

export default ImagenControl;
