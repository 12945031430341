import React, { useEffect } from "react";
import CRUDPage from "App/components/skeleton/CRUDPage";
import useAxios from "hooks/useAxios";

const Tipo = () => {
  const ListaTipos = useAxios("GET", "/tipo/listar");
  const EditarTipo = useAxios("POST", "/tipo/editar", {}, false);
  const CrearTipo = useAxios("POST", "/tipo/agregar", {}, false);
  const EliminarTipo = useAxios("POST", "/tipo/eliminar", {}, false);

  const onAction = (action, data) => {
    switch (action) {
      case "add":
        CrearTipo.updateParams(data);
        CrearTipo.initRequest();
        break;
      case "edit":
        EditarTipo.updateParams(data);
        EditarTipo.initRequest();
        break;
      case "delete":
        EliminarTipo.updateParams(data);
        EliminarTipo.initRequest();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (CrearTipo.responseCode === 200) ListaTipos.sendRequest();
    if (EditarTipo.responseCode === 200) ListaTipos.sendRequest();
    if (EliminarTipo.responseCode === 200) ListaTipos.sendRequest();

    return () => {};
    //eslint-disable-next-line
  }, [
    CrearTipo.responseCode,
    EditarTipo.responseCode,
    EliminarTipo.responseCode,
  ]);

  return (
    <div>
      <CRUDPage
        title="Lista de Tipos"
        useDefaultActions
        data={ListaTipos.data}
        onAction={onAction}
      />
    </div>
  );
};

export default Tipo;
