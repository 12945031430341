import React, { useEffect } from "react";
import CRUDPage from "App/components/skeleton/CRUDPage";
import { getImagenField, subirImagen } from "core/utils/imagenValidator";
import useAxios from "hooks/useAxios";
import { Row, Column, Heading, Typography } from "core/util-styled-components";
import { Image } from "antd";
import { isUrl } from "core/util-functions/src/core/strings";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";

const Autores = () => {
  const AutoresAgregar = useAxios("POST", "/autor/agregar", {}, false);
  const AutoresModificar = useAxios("POST", "/autor/modificar", {}, false);
  const AutoresListar = useAxios("GET", "/autor/listar");
  const AutoresEliminar = useAxios("POST", "/autor/eliminar", {}, false);

  useEffect(() => {
    if (AutoresAgregar.responseCode !== null) {
      AutoresListar.initRequest();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AutoresAgregar.responseCode]);

  const onAction = async (action, data) => {
    if (action === "add") {
      let URLImage =
        "https://media.hsantarosa.gob.pe//uploads/img/751003-360_f_223507349_f5rfu3kl6emt5lijoambwlehutv165cb.jpg";
      if (data.Foto !== undefined) {
        const Image = getImagenField(data.Foto);
        if (Image) {
          URLImage = await subirImagen(Image);
        }
      }

      const params = {
        ...data,
        Foto: URLImage,
      };

      AutoresAgregar.updateParams(params);
      AutoresAgregar.initRequest();
    }
    if (action === "edit") {
      let URLImage = "";

      if (isUrl(data.Foto)) {
        URLImage = data.Foto;
      } else {
        const Image = getImagenField(data.Foto);

        if (Image) {
          URLImage = await subirImagen(Image);
        }
      }

      const params = {
        ...data,
        Foto: URLImage,
      };

      AutoresModificar.updateParams(params);
      AutoresModificar.initRequest();
    }
    if (action === "delete") {
      AutoresEliminar.updateParams(data);
      AutoresEliminar.initRequest();
    }
  };

  useEffect(() => {
    if (AutoresAgregar.responseCode === 200) AutoresListar.sendRequest();
    if (AutoresModificar.responseCode === 200) AutoresListar.sendRequest();
    if (AutoresEliminar.responseCode === 200) AutoresListar.sendRequest();

    return () => {};
    //eslint-disable-next-line
  }, [
    AutoresAgregar.responseCode,
    AutoresModificar.responseCode,
    AutoresEliminar.responseCode,
  ]);
  const { isTabletOrMobile } = useMediaQueryCustom();
  return (
    <div>
      <CRUDPage
        title="Lista de Autores"
        span={2}
        data={AutoresListar.data}
        form={[
          {
            name: "Nombre",
            type: "input",
            required: true,
          },
          {
            name: "Descripcion",
            type: "input-description",
          },
          {
            name: "Foto",
            type: "imagen",
            customProps: {
              spanCol: "2/3",
              spanRow: "1/3",
            },
          },
        ]}
        rowRender={(data) => {
          return (
            <Row gap="10px">
              <Image
                src={data.Foto}
                width="50px"
                style={{ maxWidth: "50px", width: "50px", minWidth: "50px" }}
              />
              <Column alignItems="flex-start">
                <Heading style={{ margin: 0, padding: 0 }}>
                  {data.Nombre}
                </Heading>
                {!isTabletOrMobile && (
                  <Typography>{data.Descripcion}</Typography>
                )}
              </Column>
            </Row>
          );
        }}
        onAction={onAction}
        useDefaultActions
      />
    </div>
  );
};

export default Autores;
