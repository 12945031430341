export const Theming = {
  colors: {
    primary: "#15A7FF",
    primaryHover: "#32B2FF",
    secondary: "#DEECF5",
    complementary: "#FF6D15",
    analogy: "#FF1532",
    neutro: "#156DFF",
    error: "#FF4F76",
    warning: "#FFB14F",
    success: "#C4EA0F",
    info: "#4759FF",
    text: "#49494B",
    textInverse: "#F3F3F8",
    background: "#F5F5F5",
    backgroundInverse: "#49494B",
    border: "#EAEAEA",
    borderInverse: "#555555",
    white: "#FFFFFF",
  },
  fonts: {
    fontFamily: "Lato, sans-serif",
    fontSecondaryFamily: "Poppins, sans-serif",
  },
};
