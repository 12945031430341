import styled from "styled-components";

export const LogoStyled = styled.div`
  width: calc(200px + 3em);
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  padding: 0;
  img {
    width: 2.5em;
  }
  h3 {
    font-size: 10pt;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.fontSecondaryFamily};
    text-align: left;
    margin: 0;
    padding: 0;
    font-weight: 600;
  }
`;
