import { Button } from "antd";
import FormControl from "../../Molecules/FormControl";
import { Column, Row, Heading, Typography } from "core/util-styled-components";
// import { FormProvider, useForm } from "react-hook-form";
import { IInputPayload } from "../../../../types/interfaces";
import { GridChild } from "core/util-styled-components/components/Grid";
import SelectorDisplays from "core/util-styled-components/components/SelectorDisplays";

const FormControls = ({ children, ...restProps }: any) => {
  return (
    <Column gap={restProps.gap ? restProps.gap : "10px"}>
      {restProps.inputs && (
        <Controls
          inputs={restProps.inputs}
          cols={restProps.cols}
          type={restProps.type}
          orientation={restProps.orientation}
          gap={restProps.gap}
        />
      )}
      {children}
      <Row
        style={{
          alignSelf: !restProps.buttonSubmitLargerAndOnly
            ? "flex-end"
            : "flex-start",
          width: restProps.buttonSubmitLargerAndOnly ? "100%" : "auto",
        }}
        gap="10px"
      >
        {!restProps.buttonSubmitLargerAndOnly && (
          <Button
            type="link"
            onClick={restProps.onCancel ? restProps.onCancel : () => {}}
          >
            <Typography fw="bold" color="white">
              {restProps.cancelLabel || "Cancelar"}
            </Typography>
          </Button>
        )}
        <Button
          type="primary"
          htmlType="submit"
          style={restProps.buttonSubmitLargerAndOnly && { width: "100%" }}
        >
          <Typography fw="bold" color="white">
            {restProps.submitLabel || "Guardar"}
          </Typography>
        </Button>
      </Row>
    </Column>
  );
};

FormControls.Extra = ({ children }: any) => <div>{children}</div>;

const Controls = ({
  inputs,
  cols,
  type = "grid",
  orientation,
  gap = "10px",
}: any) => (
  <SelectorDisplays type={type} gap={gap} cols={cols}>
    {inputs.map((el: IInputPayload, key: number) => {
      if (el.type === "title-separator") {
        return (
          <GridChild
            span={cols}
            spanCol={el.customProps ? el.customProps.spanCol : undefined}
            spanRow={el.customProps ? el.customProps.spanRow : undefined}
          >
            <Heading variant="h3" style={{ margin: 0, padding: 0 }}>
              {el.name}
            </Heading>
          </GridChild>
        );
      }
      if (el.type === "custom") {
        return (
          <GridChild
            span={cols}
            spanCol={el.customProps ? el.customProps.spanCol : undefined}
            spanRow={el.customProps ? el.customProps.spanRow : undefined}
          >
            <Column gap="10px">
              {el.name && (
                <Heading variant="h4" style={{ margin: 0, padding: 0 }}>
                  {el.name}
                </Heading>
              )}
              {el.customProps?.render}
            </Column>
          </GridChild>
        );
      }
      if (el.fullWidth) {
        return (
          <GridChild
            span={cols}
            spanCol={el.customProps ? el.customProps.spanCol : undefined}
            spanRow={el.customProps ? el.customProps.spanRow : undefined}
          >
            <FormControl
              fluid={type !== "grid"}
              key={key}
              orientation={orientation}
              {...el}
            />
          </GridChild>
        );
      }
      return (
        <FormControl
          fluid={type !== "grid"}
          key={key}
          orientation={orientation}
          {...el}
        />
      );
    })}
  </SelectorDisplays>
);

FormControls.Controls = Controls;

export default FormControls;
