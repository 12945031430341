import React, { useEffect } from "react";
import CRUDPage from "App/components/skeleton/CRUDPage";
import useAxios from "hooks/useAxios";

const Idioma = () => {
  const ListaIdiomas = useAxios("GET", "/idioma/listar");
  const EditarIdioma = useAxios("POST", "/idioma/editar", {}, false);
  const CrearIdioma = useAxios("POST", "/idioma/agregar", {}, false);
  const EliminarIdioma = useAxios("POST", "/idioma/eliminar", {}, false);

  const onAction = (action, data) => {
    switch (action) {
      case "add":
        CrearIdioma.updateParams(data);
        CrearIdioma.initRequest();
        break;
      case "edit":
        EditarIdioma.updateParams(data);
        EditarIdioma.initRequest();
        break;
      case "delete":
        EliminarIdioma.updateParams(data);
        EliminarIdioma.initRequest();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (CrearIdioma.responseCode === 200) ListaIdiomas.sendRequest();
    if (EditarIdioma.responseCode === 200) ListaIdiomas.sendRequest();
    if (EliminarIdioma.responseCode === 200) ListaIdiomas.sendRequest();

    return () => {};
    //eslint-disable-next-line
  }, [
    CrearIdioma.responseCode,
    EditarIdioma.responseCode,
    EliminarIdioma.responseCode,
  ]);

  return (
    <div>
      <CRUDPage
        title="Lista de Idiomas"
        useDefaultActions
        data={ListaIdiomas.data}
        onAction={onAction}
      />
    </div>
  );
};

export default Idioma;
