import CRUDPage from "App/components/skeleton/CRUDPage";
import useAxios from "hooks/useAxios";
import React from "react";

const Especialidades = () => {
  const ListaEspecialidades = useAxios("GET", "/especialidades/listar");
  return (
    <CRUDPage
      title="Lista de especialidades"
      rowRender={(row) => (
        <div style={{ margin: "10px 0" }}>{row.NombreEspecialidad}</div>
      )}
      data={ListaEspecialidades.data}
      readOnly
    />
  );
};

export default Especialidades;
