import axios from 'axios'
import { useEffect, useState } from 'react'
import AxiosTokenProvider from 'axios-token-interceptor'
import { useUsuario } from './ContextUser'
import { message } from 'antd'

export const urlUadiResidentado = '/api/biblioteca-v2'

// export const url = `http://localhost:5000/${urlUadiResidentado}`;
export const url = `https://elzeein.com:5001`
export const params = `/api/v1/consumer`

function useAxios (
  METHOD,
  URL,
  PARAMS = null,
  initialFlag = true,
  NotBaseUrl = false,
  PredetermidateMessage = false,
  addUserOnParams = false
) {
  // const [PARAMS, setPARAMS] = useState(_PARAMS);
  const { usuario, tokenInterceptor } = useUsuario()
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [responseCode, setResponseCode] = useState()
  const [loading, setLoading] = useState(false)

  const reset = () => {
    setResponseCode(null)
    setData([])
    setError(null)
    setLoading(false)
  }

  const updateURL = newURL => {
    URL = newURL
  }
  const updateParams = newParams => {
    PARAMS = newParams
  }

  useEffect(() => {
    if (PredetermidateMessage) {
      if (responseCode === 200) {
        message.success('Operación realizada con éxito')
      }
      if (responseCode === 400) {
        message.error('Error en la operación')
      }
    }

    return () => {}
    // eslint-disable-next-line
  }, [responseCode])

  const getInstance = () => {
    const instance = axios.create({ baseURL: NotBaseUrl ? '' : url })
    usuario &&
      instance.interceptors.request.use(
        AxiosTokenProvider({
          getToken: () => tokenInterceptor
        })
      )
    return instance
  }

  const getRequest = async instance => {
    let request = null
    request = await instance.post(params, {
      route: urlUadiResidentado + URL,
      params: addUserOnParams
        ? { ...PARAMS, idUsuario: usuario?.user.id }
        : PARAMS,
      method: METHOD
    })
    return request
  }

  const sendRequest = async () => {
    setLoading(true)
    try {
      const instance = getInstance()
      const response = await getRequest(instance)
      setResponseCode(200)
      setData(response ? response.data : [])
      setLoading(false)
    } catch (error) {
      setData([])
      setResponseCode(500)
      setError(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (initialFlag) {
      initRequest()
    }
    //eslint-disable-next-line
  }, [initialFlag])

  const initRequest = () => {
    reset()
    sendRequest()
  }

  return {
    data,
    loading,
    error,
    responseCode,
    setData,
    updateURL,
    updateParams,
    initRequest,
    sendRequest,
    reset
  }
}

export default useAxios
