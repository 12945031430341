const useLocalStorage = (name) => {

    const setContent = (content) => {
        localStorage.setItem(name,JSON.stringify(content))
    }
    const getContent = () => {
        return JSON.parse(localStorage.getItem(name))
    }
    return ([setContent,getContent])
}
export default useLocalStorage;
