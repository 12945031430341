import DrawerX from "./Drawer";
import NavBar from "./Navbar";
import { useState } from "react";
const Layout = ({ children }) => {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <NavBar setVisible={setVisible} />
      <DrawerX visible={visible} setVisible={setVisible} />
      {children}
    </div>
  );
};

export default Layout;
