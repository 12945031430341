import React, { useEffect } from "react";
import CRUDPage from "App/components/skeleton/CRUDPage";
import useAxios from "hooks/useAxios";

const Area = () => {
  const ListaAreas = useAxios("GET", "/area/listar");
  const EditarArea = useAxios("POST", "/area/editar", {}, false);
  const CrearArea = useAxios("POST", "/area/agregar", {}, false);
  const EliminarArea = useAxios("POST", "/area/eliminar", {}, false);

  const onAction = (action, data) => {
    switch (action) {
      case "add":
        CrearArea.updateParams(data);
        CrearArea.initRequest();
        break;
      case "edit":
        EditarArea.updateParams(data);
        EditarArea.initRequest();
        break;
      case "delete":
        EliminarArea.updateParams(data);
        EliminarArea.initRequest();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (CrearArea.responseCode === 200) ListaAreas.sendRequest();
    if (EditarArea.responseCode === 200) ListaAreas.sendRequest();
    if (EliminarArea.responseCode === 200) ListaAreas.sendRequest();

    return () => {};
    //eslint-disable-next-line
  }, [
    CrearArea.responseCode,
    EditarArea.responseCode,
    EliminarArea.responseCode,
  ]);

  return (
    <div>
      <CRUDPage
        title="Lista de areas"
        useDefaultActions
        data={ListaAreas.data}
        onAction={onAction}
      />
    </div>
  );
};

export default Area;
