import React from "react";
import Area from "App/pages/Administrador/Area";
import Colleciones from "App/pages/Administrador/Colleciones";
import Idioma from "App/pages/Administrador/Idioma";
import Tema from "App/pages/Administrador/Tema";
import Tipo from "App/pages/Administrador/Tipo";
import Login from "App/pages/Login";
import { Route, Routes } from "react-router-dom";
import Especialidades from "App/pages/Administrador/Especialidades";
import Usuarios from "App/pages/Administrador/Usuarios";
import ContenidoEscrito from "App/pages/Administrador/ContenidoEscrito";
import ContenidoMultimedia from "App/pages/Administrador/ContenidoMultimedia";
import Autores from "App/pages/Administrador/Autores";
import Home from "App/pages";
import PublicacionEscrita from "App/pages/Cliente/PublicacionEscrita";
import PublicacionMultimedia from "App/pages/Cliente/PublicacionMultimedia";
import Buscar from "App/pages/Cliente/Buscar";
import { useUsuario } from "hooks/ContextUser";

const Router = () => {
  const { usuario } = useUsuario();
  return (
    <Routes>
      <Route path="/">
        <Route index element={usuario ? <Home /> : <Login />} />
        <Route path="login" element={<Login />} />
        {usuario && (
          <Route path="cliente">
            <Route
              path="publicacionEscrita/:id"
              element={<PublicacionEscrita />}
            />
            <Route
              path="publicacionMultimedia/:id"
              element={<PublicacionMultimedia />}
            />
            <Route path="buscar/:keyword" element={<Buscar />} />
          </Route>
        )}
        {usuario && (
          <Route path="administrador">
            <Route path="area" element={<Area />} />
            <Route path="collecion" element={<Colleciones />} />
            <Route path="idioma" element={<Idioma />} />
            <Route path="tema" element={<Tema />} />
            <Route path="tipo" element={<Tipo />} />
            <Route path="especialidades" element={<Especialidades />} />
            <Route path="usuarios" element={<Usuarios />} />
            <Route path="autores" element={<Autores />} />
            <Route path="contenido-escrito" element={<ContenidoEscrito />} />
            <Route
              path="contenido-multimedia"
              element={<ContenidoMultimedia />}
            />
          </Route>
        )}
      </Route>
    </Routes>
  );
};

export default Router;
