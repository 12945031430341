import { useParams } from "react-router-dom";
import { Container } from "App/components/skeleton/Container";
import {
  Heading,
  Grid,
  Typography,
  Div,
  Row,
  Column,
} from "core/util-styled-components";
import useAxios from "hooks/useAxios";
import React from "react";
import { GridChild } from "core/util-styled-components/components/Grid";
import { Card, Image, Tag } from "antd";
import { geekblue } from "@ant-design/colors";
import { getEmbedYoutube } from "core/utils/youtubeValidator";

const PublicacionMultimedia = () => {
  const { id } = useParams();

  const PublicacionMultimedia = useAxios(
    "GET",
    `/contenidoMultimedia/consultar/cliente/${id}`
  );

  return (
    <Div width="80%" margin="20px auto">
      {Array.isArray(PublicacionMultimedia.data) &&
        PublicacionMultimedia.data.map((item) => (
          <Grid cols={12} gap="10px" spanRow="1/3">
            <GridChild span={8}>
              <Card
                title={`Publicacion Multimedia #${item.IdVideo}`}
                extra={
                  <Tag color={geekblue.primary}>
                    <Typography color="white">
                      {item.TipoPublicacion}
                    </Typography>
                  </Tag>
                }
              >
                <Grid cols={4} gap="0px 20px">
                  <GridChild span={4}>
                    <Row justifyContent="space-between">
                      <Heading variant="h2">{item.Nombre}</Heading>
                    </Row>
                  </GridChild>
                  <GridChild span={4}>
                    <iframe
                      width="100%"
                      height="350"
                      src={getEmbedYoutube(item.URL)}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </GridChild>

                  <GridChild span={4} style={{ alignSelf: "flex-start" }}>
                    <Column gap="10px">
                      <strong>Descripcion </strong>
                      <Typography>{item.Descripcion}</Typography>
                    </Column>
                  </GridChild>
                </Grid>
              </Card>
            </GridChild>
            <GridChild span={4}>
              <Card title="Informacion">
                <Column gap="10px">
                  <Image src={item.Miniatura} style={{ borderRadius: "5px" }} />
                  <Item
                    label="Fecha Contenido"
                    value={
                      item.FechaPublicacion
                        ? new Date(item.FechaPublicacion)
                            .toISOString()
                            .split("T")[0]
                        : "No Registrado"
                    }
                  />
                  <Item
                    label="Area de Publicacion"
                    value={
                      item.AreaPublicacion
                        ? item.AreaPublicacion
                        : "No Registrado"
                    }
                  />
                  <Item
                    label="Tema de Publicacion"
                    value={
                      item.TemaPublicacion
                        ? item.TemaPublicacion
                        : "No Registrado"
                    }
                  />
                  <Item
                    label="Idioma"
                    value={
                      item.IdiomaPublicacion
                        ? item.IdiomaPublicacion
                        : "No Registrado"
                    }
                  />
                  {/* <a
                    href={item.PDF}
                    target="_blank"
                    rel="noreferrer"
                    style={{ width: "100%" }}
                  >
                    <Button type="primary" style={{ width: "100%" }}>
                      Ver Contenido
                    </Button>
                  </a> */}
                </Column>
              </Card>
            </GridChild>
          </Grid>
        ))}
    </Div>
  );
};

const Item = ({ label, value }) => {
  return (
    <Row gap="5px" justifyContent="space-between">
      <strong>{label}</strong>
      <Typography style={{ textAlign: "end" }}>{value}</Typography>
    </Row>
  );
};

export default PublicacionMultimedia;
