import {
  faArrowRightToBracket,
  faRibbon,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getUrl = (keyPath = []) => {
  const reverse = keyPath.reverse();
  return reverse.reduce(
    (acc, curr) => `${acc}${curr === "rc-menu-more" ? "" : curr}/`,
    ""
  );
};
export const mapAdmin = {
  label: "Administrador",
  key: "administrador",
  icon: <FontAwesomeIcon icon={faRibbon} className="icon-primary" />,
  children: [
    {
      label: "Contenido escrito",
      key: "contenido-escrito",
    },
    {
      label: "Contenido multimedia",
      key: "contenido-multimedia",
    },
    {
      label: "Autores",
      key: "autores",
    },
    {
      label: "Area de investigación",
      key: "area",
    },
    {
      label: "Especialidades",
      key: "especialidades",
    },
    {
      label: "Colleciones",
      key: "collecion",
    },
    {
      label: "Tipo de publicacion",
      key: "tipo",
    },
    {
      label: "Tema de publicacion",
      key: "tema",
    },
    {
      label: "Idioma",
      key: "idioma",
    },
    {
      label: "Usuarios",
      key: "usuarios",
    },
  ],
};
