import React, { useEffect } from "react";
import CRUDPage from "App/components/skeleton/CRUDPage";
import useAxios from "hooks/useAxios";

const Tema = () => {
  const ListaTemas = useAxios("GET", "/tema/listar");
  const EditarTema = useAxios("POST", "/tema/editar", {}, false);
  const CrearTema = useAxios("POST", "/tema/agregar", {}, false);
  const EliminarTema = useAxios("POST", "/tema/eliminar", {}, false);

  const onAction = (action, data) => {
    switch (action) {
      case "add":
        CrearTema.updateParams(data);
        CrearTema.initRequest();
        break;
      case "edit":
        EditarTema.updateParams(data);
        EditarTema.initRequest();
        break;
      case "delete":
        EliminarTema.updateParams(data);
        EliminarTema.initRequest();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (CrearTema.responseCode === 200) ListaTemas.sendRequest();
    if (EditarTema.responseCode === 200) ListaTemas.sendRequest();
    if (EliminarTema.responseCode === 200) ListaTemas.sendRequest();

    return () => {};
    //eslint-disable-next-line
  }, [
    CrearTema.responseCode,
    EditarTema.responseCode,
    EliminarTema.responseCode,
  ]);

  return (
    <div>
      <CRUDPage
        title="Lista de Temas"
        useDefaultActions
        data={ListaTemas.data}
        onAction={onAction}
      />
    </div>
  );
};

export default Tema;
