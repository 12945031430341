import React, { useState, useEffect, useMemo } from 'react'
import Axios from 'axios'
import useLocalStorage from './useLocalStorage'
import { params, url, urlUadiResidentado } from './useAxios'
// import { useNavigate } from "react-router-dom";

const UsuarioContext = React.createContext()
export function UsuarioProvider (props) {
  const [initVal, setInitVal] = useState(true)
  const [userIsLogged, setUserIsLogged] = useState(false)
  const [usuario, setUsuario] = useState(null)
  const [setLogin, getLogin] = useLocalStorage('BibliotecaLogin@v2')
  const [tokenInterceptor, setTokenInterceptor] = useState('')
  useEffect(() => {
    recuperarUsuario()
    setInitVal(false)
    return () => {}
    //eslint-disable-next-line
  }, [])

  const recuperarUsuario = async e => {
    // changeLoading();
    const store = getLogin()
    if (store && store.login) {
      setUserIsLogged(true)
      setUsuario(store)
      setTokenInterceptor(store.store + ' ' + store.user.idrol)
    } else {
      setTokenInterceptor('')
      setUserIsLogged(false)
      setUsuario(null)
    }
    // changeLoading(false);
  }
  const login = async (username, password) => {
    // changeLoading();
    try {
      const res = await Axios.post(`${url}${params}`, {
        route: urlUadiResidentado + '/login/2',
        method: 'POST',
        params: {
          username: username,
          password: password
        }
      })
      const { user, token } = res.data
      if (user && token) {
        setLogin({
          login: true,
          store: token,
          user
        })
        recuperarUsuario()
      } else {
        return [
          'AUTENTICACION NO VALIDA',
          'EL USUARIO NO TIENE LA CONFIGURACION CORRECTA',
          501
        ]
      }

      // changeLoading(false);
      return ['AUTENTICACION CORRECTA', '¡Bienvenido!', 200]
    } catch (error) {
      // changeLoading(false);
      return [
        'AUTENTICACION INCORRECTA',
        'VERIFIQUE SU USUARIO Y CONTRASEÑA',
        501
      ]
    }
  }
  const logout = e => {
    localStorage.removeItem('ResidentadoUserLogin')
    setUserIsLogged(false)
    sessionStorage.clear()
    setUsuario(null)
  }

  const value = useMemo(() => {
    return {
      usuario,
      login,
      logout,
      initVal,
      userIsLogged,
      tokenInterceptor
    }
    //eslint-disable-next-line
  }, [usuario, initVal, userIsLogged])
  return <UsuarioContext.Provider value={value} {...props} />
}
export function useUsuario () {
  const context = React.useContext(UsuarioContext)
  if (!context) {
    throw new Error('No se encontro Usuario en el contexto')
  }
  return context
}
