import React from "react";
import { Container } from "App/components/skeleton/Container";
import Toolbar from "App/components/molecules/Toolbar/Toolbar";
import ListaLibros from "App/components/organisms/ListaLibros";
import ListaVideos from "App/components/organisms/ListaVideos";

const Home = () => {
  return (
    <div>
      <Toolbar />
      <Container>
        <ListaLibros />
        <ListaVideos />
      </Container>
    </div>
  );
};

export default Home;
