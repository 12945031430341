import React, { useEffect, useState } from "react";
import { Avatar, Image } from "antd";
import CRUDPage from "App/components/skeleton/CRUDPage";
import { Row } from "core/util-styled-components";
import useAxios from "hooks/useAxios";
import {
  getField,
  getImagenField,
  subirImagen,
  subirPDF,
} from "core/utils/imagenValidator";
import { faCogs, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { isUrl } from "core/util-functions/src/core/strings";
import { textField } from "core/utils/string";
import ConfiguracionAccesos from "App/components/organisms/ConfigurarAccesos";
import Libro from "App/components/molecules/Libro";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";

const actions = [
  {
    key: "config",
    label: "Accesos",
    icon: faCogs,
    type: "button",
  },
  {
    key: "edit",
    label: "Modificar",
    icon: faPen,
    type: "link",
  },
  {
    key: "delete",
    label: "Eliminar",
    icon: faTrash,
    type: "link",
    danger: true,
  },
];

const ContenidoEscrito = () => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  const [config, setConfig] = useState(false);
  const [configData, setConfigData] = useState(null);
  const Tipos = useAxios("GET", "/tipo/listar");
  const Tema = useAxios("GET", "/tema/listar");
  const Area = useAxios("GET", "/area/listar");
  const Idioma = useAxios("GET", "/idioma/listar");
  const AutoresListar = useAxios("GET", "/autor/listar");
  const ContenidoEscritoAgregar = useAxios(
    "POST",
    "/contenidoEscrito/Agregar",
    {},
    false
  );
  const ContenidoEscritoModificar = useAxios(
    "POST",
    "/contenidoEscrito/Modificar",
    {},
    false
  );
  const ContenidoEscritoListar = useAxios("GET", "/contenidoEscrito/listar");
  const ContenidoEscritoEliminar = useAxios(
    "POST",
    "/contenidoEscrito/Eliminar",
    {},
    false
  );

  useEffect(() => {
    if (ContenidoEscritoAgregar.responseCode === 200)
      ContenidoEscritoListar.sendRequest();
    if (ContenidoEscritoModificar.responseCode === 200)
      ContenidoEscritoListar.sendRequest();
    if (ContenidoEscritoEliminar.responseCode === 200)
      ContenidoEscritoListar.sendRequest();

    return () => {};
    //eslint-disable-next-line
  }, [
    ContenidoEscritoAgregar.responseCode,
    ContenidoEscritoModificar.responseCode,
    ContenidoEscritoEliminar.responseCode,
  ]);

  const onAction = async (action, data) => {
    if (action === "add") {
      let URLImage = "";
      let URLPDF = "";
      const Image = getImagenField(data.Portada);
      const PDF = getField(data.PDF, "pdf");

      if (Image) {
        URLImage = await subirImagen(Image);
      }

      if (PDF) {
        URLPDF = await subirPDF(PDF);
      }

      const params = {
        ...data,
        Portada: URLImage,
        PDF: URLPDF,
      };

      console.log(params);
      ContenidoEscritoAgregar.updateParams(params);
      ContenidoEscritoAgregar.initRequest();
    }
    if (action === "config") {
      setConfig(true);
      setConfigData(data);
    }
    if (action === "edit") {
      let URLImage = "";
      let URLPDF = "";
      if (isUrl(data.Portada)) {
        URLImage = data.Portada;
      } else {
        const Image = getImagenField(data.Portada);
        if (Image) {
          URLImage = await subirImagen(Image);
        }
      }

      if (isUrl(data.PDF)) {
        URLPDF = data.PDF;
      } else {
        const PDF = getField(data.PDF, "pdf");
        if (PDF) {
          URLPDF = await subirPDF(PDF);
        }
      }

      const params = {
        ...data,
        Portada: URLImage,
        PDF: URLPDF,
      };

      console.log(params);
      ContenidoEscritoModificar.updateParams(params);
      ContenidoEscritoModificar.initRequest();
    }
    if (action === "delete") {
      ContenidoEscritoEliminar.updateParams(data);
      ContenidoEscritoEliminar.initRequest();
    }
  };
  return (
    <div>
      {configData && (
        <ConfiguracionAccesos
          open={config}
          onCancel={() => {
            setConfig(false);
            setConfigData(null);
          }}
          data={configData}
        />
      )}
      <CRUDPage
        title="Contenido Escrito"
        cols={2}
        data={ContenidoEscritoListar.data}
        customDesignProps={{
          title: "contenido escrito",
          maSize: "100%",
          formCols: isTabletOrMobile ? 3 : 4,
        }}
        rowRender={(row) => <Libro data={row} />}
        form={[
          {
            name: "Contenido",
            type: "title-separator",
          },
          {
            name: "Nombre",
            type: "input",
            required: true,
            customProps: {
              spanCol: "span 3",
              required: true,
            },
          },
          {
            name: "Author",
            type: "dropdown",
            optionsDropdown: AutoresListar.data,
            optionLabel: "Nombre",
            optionValue: "IdAutor",
            required: true,
            customProps: {
              required: true,
              spanCol: "span 2",
              itemRenderer: ({ item, idx, props, state, methods }) => {
                return (
                  <div
                    key={"author" + idx}
                    onClick={() => methods.addItem(item)}
                  >
                    <Row gap="5px" margin="5px 8px">
                      <Avatar src={item.Foto} size={20} />
                      {textField(item[props.labelField], 40)}
                    </Row>
                  </div>
                );
              },
              contentRenderer: ({ props, state }) => {
                return props.values.length > 0 ? (
                  <Row>
                    {props.values.map((item, idx) => {
                      return (
                        <div key={"author" + idx}>
                          <Row gap="5px" margin="5px 8px">
                            <Avatar src={item.Foto} size={20} />
                            {textField(item[props.labelField], 35)}
                          </Row>
                        </div>
                      );
                    })}
                  </Row>
                ) : (
                  "No hay autores seleccionados"
                );
              },
              loading: AutoresListar.loading,
            },
          },
          {
            name: "FechaPublicacion",
            type: "input",
            defaultValue: new Date().toISOString().split("T")[0],
            required: true,
            customProps: {
              required: true,
              type: "date",
            },
          },
          {
            name: "Tipo",
            type: "dropdown",
            optionsDropdown: Tipos.data,
            optionLabel: "Descripcion",
            optionValue: "IdTipo",
            required: true,
            customProps: {
              required: true,
              loading: Tipos.loading,
            },
          },
          {
            name: "Tema",
            type: "dropdown",
            optionsDropdown: Tema.data,
            optionLabel: "Descripcion",
            optionValue: "IdTema",
            required: true,
            customProps: {
              required: true,
              spanCol: "span 2",
              loading: Tema.loading,
            },
          },
          {
            name: "Portada",
            type: "imagen",
            required: true,
            customProps: {
              required: true,
              spanCol: isTabletOrMobile ? "span 3" : "4/5",
              spanRow: "2/5",
            },
          },
          {
            name: "Sinopsis",
            type: "input-description",
            fullWidth: true,
            required: true,
            customProps: {
              required: true,
            },
          },
          // {
          //   name: "Especialidad",
          //   type: "dropdown",
          //   optionsDropdown: Especialidad.data,
          //   optionLabel: "NombreEspecialidad",
          //   optionValue: "NombreEspecialidad",
          // },
          {
            name: "PDF",
            required: true,
            type: "input",
            customProps: {
              type: "file",
              spanCol: "span 3",
            },
          },
          {
            name: "Area",
            type: "dropdown",
            optionsDropdown: Area.data,
            optionLabel: "Descripcion",
            optionValue: "IdArea",
            customProps: {
              loading: Area.loading,
              spanCol: isTabletOrMobile ? "span 3" : "span 1",
            },
          },
          {
            name: "Informacion detallada",
            type: "title-separator",
          },
          {
            name: "Editorial",
            type: "input",
          },

          {
            name: "FechaContenido",
            type: "input",
            customProps: {
              type: "date",
            },
          },
          {
            name: "AñoEdicion",
            type: "input",
            customProps: {
              type: "number",
              min: 0,
            },
          },
          {
            name: "NroPaginas",
            type: "input",
            customProps: {
              type: "number",
              min: 0,
            },
          },
          {
            name: "Idioma",
            type: "dropdown",
            optionsDropdown: Idioma.data,
            optionLabel: "Descripcion",
            optionValue: "IdIdioma",
          },
        ]}
        actions={actions}
        onAction={onAction}
        useDefaultActions
      />
    </div>
  );
};

export default ContenidoEscrito;
