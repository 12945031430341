import { Container } from "App/components/skeleton/Container";
import { Formulario } from "core/ts_formulario_model";
import { Column, Heading, Row, Typography } from "core/util-styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faUserAlt,
  faKey,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Card, message } from "antd";
import { blue } from "@ant-design/colors";
import { ReactComponent as PortadaSVG } from "assets/img/Portada.svg";
import { useUsuario } from "hooks/ContextUser";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const [showPass, setShowPass] = useState(false);
  const { login } = useUsuario();
  const nav = useNavigate();

  const verificarLogin = async ({ Usuario, Contraseña }) => {
    const response = await login(Usuario, Contraseña);

    if (response[2] === 200) {
      message.success(response[1]);
      nav("/");
    } else {
      message.error(response[1]);
    }
  };

  return (
    <Container>
      <Column style={{ height: "80vh" }} justifyContent="center" gap="1em">
        <Row responsiveReorder justifyContent="space-around">
          <div>
            <PortadaSVG
              style={{ width: "100%", height: "50vh", objectFit: "cover" }}
            />
          </div>
          <Card>
            <Formulario onSubmit={verificarLogin}>
              <Heading variant="h3">
                Bienvenido a la biblioteca virtual del HAPCSRII-2
              </Heading>

              <Formulario.FormControls
                cols={1}
                buttonSubmitLargerAndOnly
                submitLabel={"Iniciar sesión"}
                inputs={[
                  {
                    name: "Usuario",
                    type: "input",
                    customProps: {
                      prefix: (
                        <FontAwesomeIcon
                          className="icon-input"
                          color={blue.primary}
                          icon={faUserAlt}
                          style={{ marginRight: ".5em" }}
                        />
                      ),
                      size: "large",
                    },
                  },
                  {
                    name: "Contraseña",
                    type: "input",
                    customProps: {
                      type: showPass ? "text" : "password",
                      prefix: (
                        <FontAwesomeIcon
                          className="icon-input"
                          icon={faKey}
                          color={blue.primary}
                          style={{ marginRight: ".5em" }}
                        />
                      ),
                      suffix: (
                        <FontAwesomeIcon
                          color="#c3c3c3"
                          onClick={() => setShowPass(!showPass)}
                          icon={showPass ? faEyeSlash : faEye}
                        />
                      ),
                      size: "large",
                    },
                  },
                ]}
              />
            </Formulario>
          </Card>
        </Row>
        <Row alignSelf="flex-end">
          <Typography>&copy;2022 - BibliotecaVirtual - UEI</Typography>
        </Row>
      </Column>
    </Container>
  );
};

export default Login;
