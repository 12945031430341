import { blue } from "@ant-design/colors";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Image } from "antd";
import { Column, Grid, Heading, Typography } from "core/util-styled-components";
import { textField } from "core/utils/string";
import { useUsuario } from "hooks/ContextUser";
import useAxios from "hooks/useAxios";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ListaLibros = () => {
  const [size, setSize] = useState();
  const { isBigScreen, isDesktopOrLaptop, isTabletOrMobile } =
    useMediaQueryCustom();

  const ContenidoEscritoListar = useAxios(
    "POST",
    "/contenidoEscrito/listar/cliente",
    {},
    false
  );
  const { usuario } = useUsuario();
  const nav = useNavigate();

  useEffect(() => {
    if (usuario) {
      ContenidoEscritoListar.updateParams({
        idUsuario: usuario.user.id,
      });
      ContenidoEscritoListar.initRequest();
    }

    return () => {};
    //eslint-disable-next-line
  }, [usuario]);

  useEffect(() => {
    getSize();
    //eslint-disable-next-line
  }, [isBigScreen, isDesktopOrLaptop, isTabletOrMobile]);

  const getSize = () => {
    if (isBigScreen) {
      setSize(7);
    } else if (isDesktopOrLaptop) {
      setSize(5);
    } else if (isTabletOrMobile) {
      setSize(2);
    }
  };

  return (
    <Column gap="10px">
      <Heading variant="h3">
        <FontAwesomeIcon icon={faBook} color={"#FF6D15"} /> Lista de libros
      </Heading>
      <Grid cols={size} gap="15px" margin="10px 20px">
        {Array.isArray(ContenidoEscritoListar.data) &&
          ContenidoEscritoListar.data
            .filter((el, idx) => idx < size)
            .map((item) => (
              <Card
                onClick={() =>
                  nav(`/cliente/publicacionEscrita/${item.IdPublicacion}`)
                }
                cover={
                  <Image
                    src={item.Portada}
                    preview={false}
                    style={{
                      border: ".5px dashed " + blue[2],
                      minHeight: "300px",
                      maxHeight: "300px",
                      objectFit: "cover",
                    }}
                  />
                }
                hoverable
              >
                <Typography>
                  {textField(item.Nombre.toUpperCase(), 50)}
                </Typography>
              </Card>
            ))}
      </Grid>
    </Column>
  );
};

export default ListaLibros;
