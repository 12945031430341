import { blue } from "@ant-design/colors";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Image } from "antd";
import { Column, Grid, Heading } from "core/util-styled-components";
import { textField } from "core/utils/string";
import { useUsuario } from "hooks/ContextUser";
import useAxios from "hooks/useAxios";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ListaVideos = () => {
  const [size, setSize] = useState();
  const { isBigScreen, isDesktopOrLaptop, isTabletOrMobile } =
    useMediaQueryCustom();
  const ContenidoMultimediaListar = useAxios(
    "POST",
    "/contenidoMultimedia/listar/Cliente",
    {},
    false
  );
  useEffect(() => {
    getSize();
    //eslint-disable-next-line
  }, [isBigScreen, isDesktopOrLaptop, isTabletOrMobile]);

  const getSize = () => {
    if (isBigScreen) {
      setSize(5);
    } else if (isDesktopOrLaptop) {
      setSize(4);
    } else if (isTabletOrMobile) {
      setSize(1);
    }
  };

  const { usuario } = useUsuario();

  useEffect(() => {
    if (usuario) {
      ContenidoMultimediaListar.updateParams({
        idUsuario: usuario.user.id,
      });
      ContenidoMultimediaListar.initRequest();
    }

    return () => {};
    //eslint-disable-next-line
  }, [usuario]);

  const nav = useNavigate();
  return (
    <Column gap="10px">
      <Heading variant="h3">
        <FontAwesomeIcon icon={faVideo} color={"#FF6D15"} /> Lista de videos
      </Heading>
      <Grid cols={size} gap="15px" margin="10px 20px">
        {Array.isArray(ContenidoMultimediaListar.data) &&
          ContenidoMultimediaListar.data
            .filter((el, idx) => idx < size)
            .map((item) => (
              <Card
                onClick={() =>
                  nav(`/cliente/publicacionMultimedia/${item.IdVideo}`)
                }
                cover={
                  <Image
                    preview={false}
                    src={item.Miniatura}
                    style={{
                      border: ".5px dashed " + blue[2],
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                }
                hoverable
              >
                <Heading>{textField(item.Nombre.toUpperCase(), 70)}</Heading>
              </Card>
            ))}
      </Grid>
    </Column>
  );
};

export default ListaVideos;
