import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Column, Heading, Row } from "core/util-styled-components";
import { Input } from "antd";
import Select from "react-dropdown-select";
import useAxios from "hooks/useAxios";
import { Container } from "App/components/skeleton/Container";
import { useUsuario } from "hooks/ContextUser";
import Video from "App/components/molecules/Video";
import Libro from "App/components/molecules/Libro";

const Buscar = () => {
  const { keyword } = useParams();
  const ListaAreas = useAxios("GET", "/area/listar");
  const ListaTipos = useAxios("GET", "/tipo/listar");
  const BuscarAPI = useAxios("POST", `/buscar`, { keyword }, false);
  const [dataStore, setDataStore] = useState([]);
  const [areaSeleccionada, setAreaSeleccionada] = useState([]);
  const [tipoSeleccionado, setTipoSeleccionado] = useState([]);

  const { usuario } = useUsuario();

  useEffect(() => {
    if (usuario) {
      BuscarAPI.updateParams({
        idUsuario: usuario.user.id,
        keyword,
      });
      BuscarAPI.initRequest();
    }

    return () => {};
    //eslint-disable-next-line
  }, [usuario, keyword]);

  useEffect(() => {
    if (BuscarAPI.data) {
      setDataStore(BuscarAPI.data);
    }
  }, [BuscarAPI.data]);

  useEffect(() => {
    if (BuscarAPI.data.length > 0) {
      applyFilter();
    }
    return () => {};
    //eslint-disable-next-line
  }, [BuscarAPI.data, areaSeleccionada, tipoSeleccionado]);

  const onSearch = (keyword) => {
    BuscarAPI.updateParams({
      idUsuario: usuario.user.id,
      keyword,
    });
    BuscarAPI.initRequest();
  };

  const applyFilter = () => {
    const [_data] = BuscarAPI.data;

    let libros = _data.libros;
    let videos = _data.videos;

    if (areaSeleccionada.length > 0) {
      libros = libros.filter(
        (libro) => parseInt(libro.Area) === parseInt(areaSeleccionada[0].IdArea)
      );
      videos = videos.filter(
        (video) => parseInt(video.Area) === parseInt(areaSeleccionada[0].IdArea)
      );
    }
    if (tipoSeleccionado.length > 0) {
      libros = libros.filter(
        (libro) => parseInt(libro.Tipo) === parseInt(tipoSeleccionado[0].IdTipo)
      );
      videos = videos.filter(
        (video) => parseInt(video.Tipo) === parseInt(tipoSeleccionado[0].IdTipo)
      );
    }
    setDataStore([{ libros, videos }]);
  };

  return (
    <div>
      <Row padding="2.5em 2em" responsiveReorder gap="10px">
        <Input.Search
          size="large"
          minLength={3}
          placeholder={`Buscar un libro como: ${keyword}`}
          onSearch={onSearch}
        />
        <Row gap="15px">
          <Select
            options={ListaAreas.data}
            values={areaSeleccionada}
            onChange={(values) => setAreaSeleccionada(values)}
            labelField="Descripcion"
            valueField="Descripcion"
            style={{ width: "200px" }}
            clearable
          />
          <Select
            options={ListaTipos.data}
            values={tipoSeleccionado}
            onChange={(values) => setTipoSeleccionado(values)}
            labelField="Descripcion"
            valueField="Descripcion"
            style={{ width: "160px" }}
            clearable
          />
        </Row>
      </Row>
      <Container>
        {dataStore.map(({ libros, videos }) => (
          <Column gap="20px">
            <Heading variant="h2">Libros</Heading>
            {libros.length > 0 ? (
              libros.map((libro) => <Libro data={libro} />)
            ) : (
              <Heading>No se encontraron libros</Heading>
            )}
            <Heading variant="h2">Videos</Heading>
            {videos.length > 0 ? (
              videos.map((video) => <Video data={video} />)
            ) : (
              <Heading>No hay videos para mostrar</Heading>
            )}
          </Column>
        ))}
      </Container>
    </div>
  );
};

export default Buscar;
