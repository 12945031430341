import { faBook, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, Menu } from "antd";
import { useUsuario } from "hooks/ContextUser";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUrl, mapAdmin } from "./menuItems";
function DrawerX({ visible, setVisible }) {
  const [current, setCurrent] = useState("");
  const navigate = useNavigate();
  const { logout, usuario } = useUsuario();
  const menuClick = (e) => {
    setCurrent(e.key);
    if (e.key === "logout") {
      navigate("/login");
      return logout();
    }
    navigate(getUrl(e.keyPath));
  };

  const generateMap = () => {
    // const mapUsuario = {
    //   label: "Libros",
    //   key: "libros",
    //   icon: <FontAwesomeIcon icon={faUserAlt} className="icon-primary" />,
    //   children: [
    //     {
    //       label: "Descubrir Libros",
    //       key: usuario.user.IdResidente,
    //       icon: <FontAwesomeIcon icon={faBook} className="icon-primary" />,
    //     },
    //   ],
    // };

    if (usuario.user.idrol === 2) {
      return [
        // mapUsuario,
        {
          label: "Cerrar sesión",
          key: "logout",
        },
      ];
    }

    return [
      mapAdmin,
      {
        label: "Cerrar sesión",
        key: "logout",
      },
    ];
  };

  return (
    usuario &&
    usuario.user && (
      <Drawer
        title="Menu Principal"
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        bodyStyle={{ padding: 0, margin: 0 }}
      >
        <Menu
          mode="inline"
          selectedKeys={[current]}
          onClick={menuClick}
          items={generateMap()}
        />
      </Drawer>
    )
  );
}

export default DrawerX;
