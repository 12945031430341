import { Button } from "antd";
import SelectorEspecialidades from "App/components/organisms/SelectorEspecialidades";
import { Row } from "core/util-styled-components";
import useAxios from "hooks/useAxios";
import React, { useState, useEffect } from "react";

const AccesoAContenido = ({ data, onCancel }) => {
  const [accesosSeleccionados, setAccesosSeleccionados] = useState({});

  const ListarAccesos = useAxios(
    "POST",
    "/usuarios/accesos/listar",
    { idUsuario: data.id },
    true
  );

  const ModificarAccesos = useAxios(
    "POST",
    "/usuarios/modificar/accesos",
    {},
    false,
    false,
    true
  );
  useEffect(() => {
    if (ModificarAccesos.responseCode === 200) {
      setAccesosSeleccionados({});
      onCancel();
    }

    return () => {};
    //eslint-disable-next-line
  }, [ModificarAccesos.responseCode]);

  const getAccesos = () => {
    if (ListarAccesos.data !== null && Array.isArray(ListarAccesos.data)) {
      const data = ListarAccesos.data;
      console.log("data", data);
      const accesos = {};
      data.forEach((item) => {
        accesos[item.idespecialidad] = true;
      });
      console.log("accesos", accesos);

      setAccesosSeleccionados(accesos);
    }
    return [];
  };

  useEffect(() => {
    getAccesos();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ListarAccesos.data]);
  const onSave = () => {
    const accesos = [];
    Object.keys(accesosSeleccionados).forEach((key) => {
      if (accesosSeleccionados[key]) {
        accesos.push(key);
      }
    });
    const params = {
      idUsuario: data.id,
      accesos,
    };
    ModificarAccesos.updateParams(params);
    ModificarAccesos.initRequest();
  };

  return (
    <div>
      <SelectorEspecialidades
        value={accesosSeleccionados}
        onChange={(val) => setAccesosSeleccionados(val)}
      />

      <Row justifyContent="flex-end" gap="10px" margin="10px 0 0 0">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button type="primary" onClick={onSave}>
          Guardar
        </Button>
      </Row>
    </div>
  );
};

export default AccesoAContenido;
