import React, { useEffect, useState } from "react";
import CRUDPage from "App/components/skeleton/CRUDPage";
import useAxios from "hooks/useAxios";
import { getImagenField, subirImagen } from "core/utils/imagenValidator";
import { faCogs, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  getEmbedYoutube,
  youtube_validator,
} from "core/utils/youtubeValidator";
import { isUrl } from "core/util-functions/src/core/strings";
import ConfiguracionAccesos from "App/components/organisms/ConfigurarAccesos";
import Video from "App/components/molecules/Video";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";

const actions = [
  {
    key: "config",
    label: "Accesos",
    icon: faCogs,
    type: "button",
  },
  {
    key: "edit",
    label: "Modificar",
    icon: faPen,
    type: "link",
  },
  {
    key: "delete",
    label: "Eliminar",
    icon: faTrash,
    type: "link",
    danger: true,
  },
];

const ContenidoMultimedia = () => {
  const [config, setConfig] = useState(false);
  const [configData, setConfigData] = useState(null);
  const Tipos = useAxios("GET", "/tipo/listar");
  const Tema = useAxios("GET", "/tema/listar");
  const Area = useAxios("GET", "/area/listar");
  const Idioma = useAxios("GET", "/idioma/listar");
  const [url, setUrl] = useState("");
  const { isTabletOrMobile } = useMediaQueryCustom();

  const ContenidoMultimediaAgregar = useAxios(
    "POST",
    "/contenidoMultimedia/Agregar",
    {},
    false
  );
  const ContenidoMultimediaModificar = useAxios(
    "POST",
    "/contenidoMultimedia/Modificar",
    {},
    false
  );

  const ContenidoMultimediaListar = useAxios(
    "GET",
    "/contenidoMultimedia/listar"
  );

  const ContenidoMultimediaEliminar = useAxios(
    "POST",
    "/contenidoMultimedia/Eliminar",
    {},
    false
  );

  useEffect(() => {
    if (ContenidoMultimediaAgregar.responseCode === 200)
      ContenidoMultimediaListar.sendRequest();
    if (ContenidoMultimediaModificar.responseCode === 200)
      ContenidoMultimediaListar.sendRequest();
    if (ContenidoMultimediaEliminar.responseCode === 200)
      ContenidoMultimediaListar.sendRequest();

    return () => {};
    //eslint-disable-next-line
  }, [
    ContenidoMultimediaAgregar.responseCode,
    ContenidoMultimediaModificar.responseCode,
    ContenidoMultimediaEliminar.responseCode,
  ]);

  const onAction = async (action, data) => {
    if (action === "add") {
      let URLImage =
        "https://media.hsantarosa.gob.pe//uploads/img/707425-youtube-logo-hero-1.png";
      if (data.Miniatura !== undefined) {
        const Image = getImagenField(data.Miniatura);

        if (Image) {
          URLImage = await subirImagen(Image);
        }
      }

      const params = {
        ...data,
        Miniatura: URLImage,
      };

      ContenidoMultimediaAgregar.updateParams(params);
      ContenidoMultimediaAgregar.initRequest();
    }
    if (action === "config") {
      setConfig(true);
      setConfigData(data);
    }
    if (action === "edit") {
      let URLImage = "";

      if (!isUrl(data.Miniatura)) {
        const Image = getImagenField(data.Miniatura);

        if (Image) {
          URLImage = await subirImagen(Image);
        }
      } else {
        URLImage = data.Miniatura;
      }

      const params = {
        ...data,
        Miniatura: URLImage,
      };

      ContenidoMultimediaModificar.updateParams(params);
      ContenidoMultimediaModificar.initRequest();
    }

    if (action === "delete") {
      ContenidoMultimediaEliminar.updateParams(data);
      ContenidoMultimediaEliminar.initRequest();
    }
  };
  return (
    <div>
      {configData && (
        <ConfiguracionAccesos
          open={config}
          onCancel={() => {
            setConfig(false);
            setConfigData(null);
          }}
          data={configData}
          type="2"
        />
      )}
      <CRUDPage
        title="Contenido multimedia"
        cols={2}
        data={ContenidoMultimediaListar.data}
        customDesignProps={{
          title: "contenido multimedia",
          maSize: "100%",
          formCols: isTabletOrMobile ? 3 : 4,
        }}
        rowRender={(row) => <Video data={row} />}
        form={[
          {
            name: "Nombre",
            type: "input",
            required: true,
            customProps: {
              spanCol: "span 3",
              required: true,
            },
          },
          {
            name: "Tipo",
            type: "dropdown",
            optionsDropdown: Tipos.data,
            optionLabel: "Descripcion",
            optionValue: "IdTipo",
            required: true,
            customProps: {
              required: true,
              loading: Tipos.loading,
            },
          },
          {
            name: "Tema",
            type: "dropdown",
            optionsDropdown: Tema.data,
            optionLabel: "Descripcion",
            optionValue: "IdTema",
            required: true,
            customProps: {
              required: true,
              spanCol: "span 2",
              loading: Tema.loading,
            },
          },
          {
            name: "FechaPublicacion",
            type: "input",
            defaultValue: new Date().toISOString().split("T")[0],
            required: true,
            customProps: {
              required: true,
              type: "date",
            },
          },
          {
            name: "Idioma",
            type: "dropdown",
            optionsDropdown: Idioma.data,
            optionLabel: "Descripcion",
            optionValue: "IdIdioma",
          },
          {
            name: "Area",
            type: "dropdown",
            optionsDropdown: Area.data,
            optionLabel: "Descripcion",
            optionValue: "IdArea",
            customProps: {
              loading: Area.loading,
            },
          },
          {
            name: "Miniatura",
            type: "imagen",
            customProps: {
              spanCol: isTabletOrMobile ? "span 4" : "4/5",
              spanRow: "1/4",
            },
          },
          {
            name: "Descripcion",
            type: "input-description",
            fullWidth: true,
            required: true,
            customProps: {
              required: true,
            },
          },
          {
            name: "URL",
            type: "input",
            required: true,
            onChange: (e) => setUrl(e.target.value),
            customProps: {
              spanCol: "span 4",
              required: true,
            },
          },
          {
            name: "Video",
            type: "custom",
            customProps: {
              render: youtube_validator(url) && (
                <iframe
                  width="100%"
                  height="315"
                  src={getEmbedYoutube(url)}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              ),
            },
          },
        ]}
        actions={actions}
        onAction={onAction}
        useDefaultActions
      />
    </div>
  );
};

export default ContenidoMultimedia;
