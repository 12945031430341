import CRUDPage from "App/components/skeleton/CRUDPage";
import useAxios from "hooks/useAxios";
import React, { useEffect } from "react";

const Colleciones = () => {
  const ListaCollecion = useAxios("GET", "/collecion/listar");
  const EditarCollecion = useAxios("POST", "/collecion/editar", {}, false);
  const CrearCollecion = useAxios("POST", "/collecion/agregar", {}, false);
  const EliminarCollecion = useAxios("POST", "/collecion/eliminar", {}, false);

  const onAction = (action, data) => {
    switch (action) {
      case "add":
        CrearCollecion.updateParams(data);
        CrearCollecion.initRequest();
        break;
      case "edit":
        EditarCollecion.updateParams(data);
        EditarCollecion.initRequest();
        break;
      case "delete":
        EliminarCollecion.updateParams(data);
        EliminarCollecion.initRequest();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (CrearCollecion.responseCode === 200) ListaCollecion.sendRequest();
    if (EditarCollecion.responseCode === 200) ListaCollecion.sendRequest();
    if (EliminarCollecion.responseCode === 200) ListaCollecion.sendRequest();

    return () => {};
    //eslint-disable-next-line
  }, [
    CrearCollecion.responseCode,
    EditarCollecion.responseCode,
    EliminarCollecion.responseCode,
  ]);

  return (
    <div>
      <CRUDPage
        title="Lista de Colleciones"
        useDefaultActions
        data={ListaCollecion.data}
        onAction={onAction}
      />
    </div>
  );
};

export default Colleciones;
