import { Control } from "./Control";
// import { FormControlStyled } from "./styled";
import { IInputPayload } from "../../../../types/interfaces";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { Typography, Row } from "core/util-styled-components";
import { GridChild } from "core/util-styled-components/components/Grid";
interface Props extends IInputPayload {
  orientation?: "row" | "column" | undefined;
  fluid?: boolean;
}
const ProcesarLabel = (label: string) => {
  const _label = label
    .replaceAll(/([a-z])([A-Z])/g, "$1 $2")
    .replaceAll("_", " ")
    .replaceAll("-", " ");
  return _label.charAt(0).toUpperCase() + _label.slice(1);
};
const FormControl = ({ orientation, ...rest }: Props) => {
  const { register } = useFormContext();
  useEffect(() => {
    register(rest.name, {
      required: typeof rest.required === "undefined" ? false : rest.required,
    });
    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <GridChild
      span={1}
      spanCol={rest.customProps ? rest.customProps.spanCol : undefined}
      spanRow={rest.customProps ? rest.customProps.spanRow : undefined}
    >
      <Row justifyContent="space-between">
        <Typography style={{ alignSelf: "flex-start", fontSize: 12.5 }}>
          {ProcesarLabel(rest.name)}{" "}
          <strong style={{ color: "red" }}> {rest.required && "*"} </strong>
        </Typography>
        {rest.customProps && rest.customProps.extra && rest.customProps.extra}
      </Row>
      <Controller
        name={rest.name}
        render={(hookForm) => (
          <Control type={rest.type} rest={rest} hookForm={hookForm} />
        )}
        defaultValue={rest.defaultValue}
      />
      {/* <FormControlStyled
        orientation={orientation}
        fullWidth={rest.fullWidth}
        disabled={rest.disabled}
        width={rest.fluid ? "100%" : "auto"}
      ></FormControlStyled> */}
    </GridChild>
  );
};
export default FormControl;
