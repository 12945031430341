import { LogoStyled } from "./styled";
import LOGOHSRP from "assets/img/LOGOHSRP.png";
import { useUsuario } from "hooks/ContextUser";
import { useNavigate } from "react-router-dom";
const Logo = () => {
  const { userIsLogged } = useUsuario();
  const nav = useNavigate();

  const handleClickLogo = () => {
    nav("/");
    nav(userIsLogged ? "/" : "/login");
  };

  return (
    <LogoStyled onClick={handleClickLogo}>
      <img src={LOGOHSRP} alt="..." />
      <h3>Hospital de la Amistad Perú Corea Santa Rosa II-2</h3>
    </LogoStyled>
  );
};

export default Logo;
