import React, { useEffect, useState } from "react";
import { Button, Modal, Tabs, Tag } from "antd";
import { Formulario } from "core/ts_formulario_model";
import useAxios from "hooks/useAxios";
import { axiosNotification } from "core/utils/notificaciones";
import { Row } from "core/util-styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import AccesoAContenido from "./AccesoAContenido";

const Configurar = ({ open, onCancel, data }) => {
  const [formActivo, setFormActivo] = useState(false);
  const ListaRoles = useAxios("POST", "/usuarios/roles/listar");

  const AgregarRol = useAxios("POST", "/usuarios/agregar/rol", {}, false);
  const ListaRolesActivos = useAxios("POST", "/usuarios/listar/rol/", {
    idusuario: data.id,
  });

  const onSubmit = (form) => {
    const params = {
      idusuarioweb: data.id,
      idrol: form.rol[0].idrol,
    };
    AgregarRol.updateParams(params);
    AgregarRol.initRequest();
  };

  useEffect(() => {
    AgregarRol.responseCode &&
      axiosNotification(false, AgregarRol.responseCode);

    if (AgregarRol.responseCode === 200) {
      setFormActivo(false);

      ListaRolesActivos.sendRequest();
    }

    return () => {};
    //eslint-disable-next-line
  }, [AgregarRol.responseCode]);

  return (
    <Modal
      open={open}
      title={"Configurar usuario"}
      onCancel={onCancel}
      style={{ width: "50%" }}
      footer={null}
      destroyOnClose
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Configurar roles" key="1">
          {formActivo ? (
            <Formulario onSubmit={onSubmit}>
              <Formulario.FormControls
                cols={1}
                buttonSubmitLargerAndOnly
                inputs={[
                  {
                    name: "rol",
                    type: "dropdown",
                    optionsDropdown: ListaRoles.data,
                    optionValue: "idrol",
                    optionLabel: "nombre",
                    required: true,
                  },
                ]}
              />
            </Formulario>
          ) : (
            <Row gap="10px">
              Rol activo:{" "}
              <Tag>{ListaRolesActivos.data.map((rol) => rol.rol)}</Tag>
              <Button type="primary" onClick={() => setFormActivo(true)}>
                <Row gap="10px">
                  <FontAwesomeIcon icon={faCogs} />
                  Modificar rol
                </Row>
              </Button>
            </Row>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Acceso a contenidos" key="2">
          <AccesoAContenido data={data} onCancel={onCancel} />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default Configurar;
