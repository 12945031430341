import styled from "styled-components";
import { Row } from "core/util-styled-components";
import { blue, geekblue } from "@ant-design/colors";

export const UserName = styled(Row)`
  background-color: ${geekblue[0]};
  padding: 0.5em 1em;
  border-radius: 0.5em;
  cursor: pointer;
  svg {
    color: ${blue.primary};
  }
`;

export const NavBarStyled = styled(Row)`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  box-shadow: 0px 10px 12px -3px rgba(99, 99, 99, 0.15);
  -webkit-box-shadow: 0px 10px 12px -3px rgba(99, 99, 99, 0.15);
  -moz-box-shadow: 0px 10px 12px -3px rgba(99, 99, 99, 0.15);
`;
