import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "antd";
import { Column, Row, Typography } from "core/util-styled-components";
import { textField } from "core/utils/string";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";
import React from "react";

const Libro = ({ data }) => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  return (
    <Row gap="10px">
      <Image
        src={data.Portada}
        shape="square"
        size="large"
        style={{ width: "100px", maxHeight: "150px", minHeight: "100px" }}
      />
      <Column alignItems="flex-start" alignSelf="flex-start" gap="0">
        <strong>{data.Nombre}</strong>
        {!isTabletOrMobile && (
          <Typography>{textField(data.Sinopsis, 342)}</Typography>
        )}
        <a href={data.PDF} target="_blank" rel="noreferrer">
          <Row gap="10px">
            <FontAwesomeIcon icon={faExternalLink} />
            <Typography>Ver PDF</Typography>
          </Row>
        </a>
      </Column>
    </Row>
  );
};

export default Libro;
