import styled from "styled-components";
import { Column } from "core/util-styled-components";

export const SearchSection = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 2em ${({ isMobile }) => (isMobile ? "1.5em" : "3em")};
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2em;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  .img-search {
    width: ${({ isMobile }) => (isMobile ? "50%" : "30%")};
    height: auto;
  }
`;

export const InputSection = styled(Column)`
  width: 100%;
  padding: 0 ${({ isMobile }) => (isMobile ? "0" : "3em")};
  gap: 1.2em;
  text-align: left;
`;
