import styled from "styled-components";
import { IGrid } from "../types/Interfaces";
import { Div } from "./Overrides";

export const Grid = styled(Div)`
  display: grid;
  grid-gap: ${(props: IGrid) => (props.gap ? props.gap : 0)};
  grid-template-columns: ${(props: IGrid) =>
    `repeat(${props.cols ? props.cols : "auto-fill"},1fr)`};
  /* @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 20rem), 1fr));
  } */
`;

export const GridChild = styled(Div)`
  grid-column: ${(props: {
    span: number;
    spanRow?: number;
    spanCol?: number;
  }) => (props.spanCol ? props.spanCol : `span ${props.span}`)};
  grid-row: ${(props: { span: number; spanRow?: number; spanCol?: number }) =>
    props.spanRow ? `${props.spanRow}` : "auto"};
`;
