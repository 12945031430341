import styled from "styled-components";
// import { Row } from "core/util-styled-components";
import { Column } from "core/util-styled-components";

export const ImagenControlStyled = styled(Column)`
  align-items: center;
  gap: 5px;
  border: dashed 1px #d9d9d9;
  padding: 2px 0;
  .ant-image-img {
    max-width: 100px;
    max-height: 100px;
    object-fit: contain;
  }
  .ant-image-mask {
    font-size: 11px;
  }
`;

export const InputButton = styled.div`
  input {
    display: none;
  }
  label {
    background-color: #1890ff;
    color: white;
    padding: 2px 8px;
    border-radius: 2px;
  }
`;
