export const generateMapTable = (jsonArray) => {
  if (!Array.isArray(jsonArray)) {
    return [];
  }
  return jsonArray.map((el) => tableColumn(el));
};

//If add a json object to the array, "dataIndex" is required
export const tableColumn = (column) => {
  if (typeof column === "object") {
    return {
      ...column,
      key: column.key ? column.key : column.dataIndex,
      title: column.title ? column.title : ProcesarLabel(column.dataIndex),
      dataIndex: column.dataIndex ? column.dataIndex : column.key,
    };
  }

  return {
    title: ProcesarLabel(column),
    key: column,
    dataIndex: column,
  };
};
const ProcesarLabel = (label) => {
  const _label = label
    ? label
        .replaceAll(/([a-z])([A-Z])/g, "$1 $2")
        .replaceAll("_", " ")
        .replaceAll("-", " ")
    : "";

  return _label ? _label.charAt(0).toUpperCase() + _label.slice(1) : "";
};
