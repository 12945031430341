import { Switch } from "antd";
import { Grid, Row, Typography } from "core/util-styled-components";
import { GridChild } from "core/util-styled-components/components/Grid";
import useAxios from "hooks/useAxios";
import React from "react";

const SelectorEspecialidades = ({ value, onChange }) => {
  const ListaEspecialidades = useAxios("GET", "/especialidades/listar");
  return (
    <Grid cols={2} gap="15px 20px">
      {ListaEspecialidades.data.map((item, index) => {
        return (
          <GridChild key={index}>
            <Row justifyContent="space-between">
              <Typography key={index}>{item.NombreEspecialidad}</Typography>
              <Switch
                size="small"
                checked={value[item.IdEspecialidad]}
                onChange={(checked) => {
                  if (checked) {
                    onChange({
                      ...value,
                      [item.IdEspecialidad]: Boolean(checked),
                    });
                  } else {
                    delete value[item.IdEspecialidad];
                    onChange({ ...value });
                  }
                }}
              />
            </Row>
          </GridChild>
        );
      })}
    </Grid>
  );
};

export default SelectorEspecialidades;
