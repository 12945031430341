import { Avatar } from "antd";
import CRUDPage from "App/components/skeleton/CRUDPage";
import { Column, Row, Typography } from "core/util-styled-components";
import useAxios from "hooks/useAxios";
import React, { useEffect, useState } from "react";
import { faPen, faTrash, faUserCog } from "@fortawesome/free-solid-svg-icons";
import Configurar from "./Configurar";
const actions = [
  {
    key: "config",
    label: "Configurar",
    icon: faUserCog,
    type: "text",
  },
  {
    key: "edit",
    label: "Modificar",
    icon: faPen,
    type: "link",
  },
];

const Usuarios = () => {
  const ListaUsuarios = useAxios("POST", "/usuario/listar");
  const EditarUsuario = useAxios("POST", "/usuario/editar", {}, false);
  const CrearUsuario = useAxios("POST", "/usuario/agregar", {}, false);
  const [configuracion, setConfiguracion] = useState({
    visible: false,
    data: null,
  });

  const onAction = (action, data) => {
    switch (action) {
      case "add":
        CrearUsuario.updateParams(data);
        CrearUsuario.initRequest();
        break;
      case "edit":
        EditarUsuario.updateParams(data);
        EditarUsuario.initRequest();
        break;
      case "config":
        setConfiguracion({ visible: true, data });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (CrearUsuario.responseCode === 200) ListaUsuarios.sendRequest();
    if (EditarUsuario.responseCode === 200) ListaUsuarios.sendRequest();

    return () => {};
    //eslint-disable-next-line
  }, [CrearUsuario.responseCode, EditarUsuario.responseCode]);
  return (
    <div>
      <CRUDPage
        data={ListaUsuarios.data}
        rowRender={(row) => (
          <Row gap="10px">
            <Avatar
              src={
                row.ruta && row.ruta !== "undefined"
                  ? row.ruta
                  : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              }
              style={{ minWidth: "30px", width: "30px", minWidth: "30px" }}
            />
            <Column alignItems="flex-start">
              <Typography fw="bold">{row.username}</Typography>
              <Typography>
                {row.Nombres} {row.Apellidos} ({row.documento})
              </Typography>
            </Column>
          </Row>
        )}
        form={[
          { name: "username", type: "input" },
          { name: "contraseña", type: "input" },
          { name: "Apellidos", type: "input" },
          { name: "Nombres", type: "input" },
          { name: "documento", type: "input" },
          { name: "ruta", type: "input" },
          {
            name: "estado",
            type: "radio-button",
            optionsDropdown: [
              { name: "Activo", value: 1 },
              { name: "Inactivo", value: 0 },
            ],
            optionValue: "value",
            optionLabel: "name",
          },
        ]}
        cols={2}
        title="Lista de usuarios"
        onAction={onAction}
        actions={actions}
        useDefaultActions
      />
      {configuracion.data && (
        <Configurar
          data={configuracion.data}
          open={configuracion.visible}
          onCancel={() => setConfiguracion({ visible: false, data: null })}
        />
      )}
    </div>
  );
};

export default Usuarios;
